// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  overflow-x: hidden;
}

body {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  background-color: #0A0A0B;
  color: #fff;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/styles/base.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,yBAAA;EACA,WAAA;AACJ;;AAEA;EACI,sBAAA;EACA,kCAAA;AACJ","sourcesContent":["html {\n    overflow-x: hidden\n}\n\nbody {\n    font-size: 16px;\n    line-height: 1.5;\n    margin: 0;\n    padding: 0;\n    background-color: #0A0A0B;\n    color: #fff;\n}\n\n* {\n    box-sizing: border-box;\n    font-family: 'Poppins', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
