// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("styles/fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("styles/fonts/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("styles/fonts/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("styles/fonts/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("styles/fonts/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
    font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/styles/fontStyles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB;kEAC8D;IAC9D,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB;kEAC6D;IAC7D,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB;kEAC2D;IAC3D,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB;kEAC+D;IAC/D,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB;kEAC4D;IAC5D,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: \"Poppins\";\n    src: local(\"Poppins\"),\n        url(\"styles/fonts/Poppins-Regular.ttf\") format(\"truetype\");\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: \"Poppins\";\n    src: local(\"Poppins\"),\n        url(\"styles/fonts/Poppins-Medium.ttf\") format(\"truetype\");\n    font-weight: 500;\n}\n\n@font-face {\n    font-family: \"Poppins\";\n    src: local(\"Poppins\"),\n        url(\"styles/fonts/Poppins-Bold.ttf\") format(\"truetype\");\n    font-weight: bold;\n}\n\n@font-face {\n    font-family: \"Poppins\";\n    src: local(\"Poppins\"),\n        url(\"styles/fonts/Poppins-SemiBold.ttf\") format(\"truetype\");\n    font-weight: 600;\n}\n\n@font-face {\n    font-family: \"Poppins\";\n    src: local(\"Poppins\"),\n        url(\"styles/fonts/Poppins-Light.ttf\") format(\"truetype\");\n    font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
