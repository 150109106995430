import { PublicClient, WalletClient, defineChain } from "viem";
import { http, createConfig } from "wagmi";

export interface EvmWalletConnect {
  contract: any;
  publicClient: PublicClient;
  walletClient: WalletClient;
  address: `0x${string}`;
}

export const seiTestnet = defineChain({
  id: 1328,
  name: "Sei Atlantic-2 EVM Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Sei",
    symbol: "SEI",
  },
  rpcUrls: {
    default: {
      http: ["https://evm-rpc-testnet.sei-apis.com"],
      webSocket: ["wss://evm-rpc-testnet.sei-apis.com"],
    },
  },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://develop.seitrace.com/" },
  },
});

export const wagmiConfig = createConfig({
  chains: [seiTestnet],
  transports: {
    [seiTestnet.id]: http(),
  },
});

export const WASM_PRECOMPILE_ABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "contractAddress",
        type: "string",
      },
      {
        internalType: "bytes",
        name: "msg",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "coins",
        type: "bytes",
      },
    ],
    name: "execute",
    outputs: [
      {
        internalType: "bytes",
        name: "response",
        type: "bytes",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "contractAddress",
        type: "string",
      },
      {
        internalType: "bytes",
        name: "req",
        type: "bytes",
      },
    ],
    name: "query",
    outputs: [
      {
        internalType: "bytes",
        name: "response",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const WASM_PRECOMPILE_ADDRESS =
  "0x0000000000000000000000000000000000001002";
